<!--  -->
<template>
  <div class="erg_imgba">
    <img src="../../image/yingdao_APPxiazaiye@2x.png" alt="" />
    <div class="xiding-r">
      <img
        v-if="isShow"
        class="rg_jijri"
        src="../../image/loading.gif"
        alt=""
      />
      <div class="xidingiejrigjirg" @click="openapp">立即下载App</div>
      <img class="eige_img" src="../../image/logo_image.png" alt="" />
      <img class="er_juie" src="../../image/logo_ziti@2x.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      isShow: false,
      baseUrl: "https://app.cheshiji.com.cn",
      downloadUrl: "",
    };
  },
  created() {},
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 获取app的版本控制
    AppFun() {
      this.$http.get(this.baseUrl + "/admin/base/config/appVersion").then((e) => {
        if (e.data.code == 200) {
          this.downloadUrl = e.data.data.downloadUrl;
        }
      });
    },
    //立即下载
    openapp() {
      var u = navigator.userAgent,
        app = navigator.appVersion;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isAndroid) {
        this.isShow = true;
        this.android();
      }
      if (isIOS) {
        alert("暂无ios系统App,敬请期待！！！！！！");
      }
    },
    android() {
      var _clickTime = new Date().getTime();
      var url = this.downloadUrl;
      window.location.href =
        "cheshiji://cheshiji/"; /***打开app的协议，有安卓同事提供***/
      //启动间隔20ms运行的定时器，并检测累计消耗时间是否超过3000ms，超过则结束
      var _count = 0,
        intHandle;
      intHandle = setInterval(function () {
        _count++;
        var elsTime = new Date().getTime() - _clickTime;
        if (_count >= 100 || elsTime > 5000) {
          clearInterval(intHandle);
          //检查app是否打开
          if (document.hidden || document.webkitHidden) {
            // 打开了
            window.location.href = "cheshiji://cheshiji/";
            window.close();
            // return;
          } else {
            // 没打开
            window.location.href = url; //下载链接
          }
        }
      }, 20);
    },
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    this.AppFun();
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}
.erg_imgba {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
}
.rg_jijri {
  width: 191px;
  height: 15px;
}
.xidingiejrigjirg {
  width: 239px;
  height: 50px;
  border: 2px solid #ffdd97;
  background: linear-gradient(360deg, #ff7e0e 0%, #f7ff00 100%);
  opacity: 1;
  border-radius: 28px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 50px;
  color: #ffffff;
  text-align: center;
  z-index: 9;
  margin-top: 4vh;
}

.xiding-r {
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 35px;
}
.erjgijh_jijegh {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 14px;
  color: #ffffff;
}
.fuzhifiwf {
  width: 82px;
  height: 23px;
  background: #ffffff;
  opacity: 1;
  border-radius: 12px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  color: #ff6600;
  margin-top: 16px;
}
.ijiiqw_jlad {
  font-size: 10px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 17px;
  color: #fdba7d;
  margin-top: 10px;
}
.eige_img {
  width: 62px;
  height: 62px;
  margin-top: 36px;
}
.er_juie {
  width: 72.97px;
  height: 21.53px;
  margin-top: 10px;
}
</style>